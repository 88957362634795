import admin from './admin';
import tool from './tool';

// ==============================|| MENU ITEMS ||============================== //

export const menuItems = {
    items: [tool]
};

export const adminMenuItems = {
    items: [admin, tool]
};

export default menuItems;
