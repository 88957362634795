// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

const other = {
    id: 'admin',
    title: <FormattedMessage id="admin-menu-title" />,
    type: 'group',
    children: [
        // {
        //     id: 'admin-scripts',
        //     title: <FormattedMessage id="admin-scripts" />,
        //     type: 'item',
        //     url: '/admin/script',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'admin-roles',
        //     title: <FormattedMessage id="admin-roles" />,
        //     type: 'item',
        //     url: '/admin/roles',
        //     icon: icons.IconHelp,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'admin-locales',
        //     title: <FormattedMessage id="admin-locales" />,
        //     type: 'item',
        //     url: '/admin/locales',
        //     icon: icons.IconSitemap,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'admin-stats',
        //     title: <FormattedMessage id="admin-stats" />,
        //     type: 'item',
        //     url: '/admin/stats',
        //     icon: icons.IconSitemap,
        //     breadcrumbs: true
        // }
    ]
};

export default other;
