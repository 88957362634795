import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';
import axios from '../utils/axios';
import { useDispatch } from '../store';
import { openSnackbar } from '../store/slices/snackbar';
import { FormattedMessage } from 'react-intl';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    isUserAdmin: false
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const snackbarDispatch = useDispatch();

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async (user) => {
                const userData = {
                    email: user?.email,
                    imageUrl: user?.photoURL,
                    name: user?.displayName,
                    googleUid: user?.uid
                };

                await axios
                    .post(process.env.REACT_APP_USER_API, userData)
                    .then((response) => {
                        if (user) {
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    isUserAdmin: user.role === 'ADMIN',
                                    user: {
                                        id: user.uid,
                                        email: user.email,
                                        name: user.displayName || 'John Doe',
                                        image: user.photoURL
                                    }
                                }
                            });
                        } else {
                            dispatch({
                                type: LOGOUT
                            });
                        }
                    })
                    .catch((error) => {
                        snackbarDispatch(
                            openSnackbar({
                                open: true,
                                message: <FormattedMessage id="backend-error-alert" />,
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch({
                            type: LOGOUT
                        });
                    });
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
