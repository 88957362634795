// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

const other = {
    id: 'tool',
    title: <FormattedMessage id="tool-menu-title" />,
    type: 'group',
    children: [
        {
            id: 'scripts',
            title: <FormattedMessage id="scripts" />,
            type: 'item',
            url: '/scripts',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
        // {
        //     id: 'roles',
        //     title: <FormattedMessage id="roles" />,
        //     type: 'item',
        //     url: '/roles',
        //     icon: icons.IconHelp,
        //     breadcrumbs: true
        // }
    ]
};

export default other;
