// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    scripts: [],
    scriptDetails: null
};

const slice = createSlice({
    name: 'script',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getScriptsSuccess(state, action) {
            state.scripts = action.payload;
            state.scriptDetails = null;
        },
        filterScriptsSuccess(state, action) {
            state.scripts = action.payload;
        },
        getScriptDetailsSuccess(state, action) {
            state.scriptDetails = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getScripts(user) {
    return async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE + '/scripts', { user });
            dispatch(slice.actions.getScriptsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterScripts(filter, user) {
    return async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE + '/scripts', { filter, user });
            dispatch(slice.actions.filterScriptsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setFavorite(id, user) {
    return async () => {
        try {
            await axios.post(process.env.REACT_APP_API_BASE + '/script/' + id + '/favourite', { user });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getScriptDetails(id) {
    return async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE + '/script/' + id + '/details');
            dispatch(slice.actions.getScriptDetailsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
