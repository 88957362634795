import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Typography } from '@mui/material';

// sample page routing
const Scripts = Loadable(lazy(() => import('views/scripts')));
const ScriptDetails = Loadable(lazy(() => import('views/scripts/ScriptDetails')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,

    children: [
        {
            path: '/',
            element: <Scripts />
        },
        {
            path: '/scripts',
            element: <Scripts />
        },
        {
            path: '/scripts/:id',
            element: <ScriptDetails />
        },
        {
            path: '*',
            element: <Typography>123</Typography>
        }
    ]
};

export default MainRoutes;
