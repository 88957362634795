export const FIREBASE_API = {
    apiKey: 'AIzaSyD_67yrcMXzmLuXPGwNxmbeTRwI2d2ZcIs',
    authDomain: 'botcstnew.firebaseapp.com',
    projectId: 'botcstnew',
    storageBucket: 'botcstnew.appspot.com',
    messagingSenderId: '587084983665',
    appId: '1:587084983665:web:ee4878be11109b006c1ad0',
    measurementId: 'G-8DCG1372JE'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const APP_PATH = '/scripts';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'dark', // light, dark
    presetColor: 'theme4', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
