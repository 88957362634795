import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

// ==============================|| AUTH ROUTING ||============================== //

const AdminRoutes = {
    path: '/admin',
    element: (
        <NavMotion>
            <AuthGuard>
                <MinimalLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        // {
        //     path: '/login',
        //     element: <AuthLogin />
        // },
        // {
        //     path: '/register',
        //     element: <AuthRegister />
        // },
        // {
        //     path: '/forgot',
        //     element: <AuthForgotPassword />
        // }
    ]
};

export default AdminRoutes;
