import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { adminMenuItems as authMenuItems, menuItems as noAuthMenuItems } from 'menu-items';
import useAuth from '../../../../hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { isLoggedIn, isUserAdmin } = useAuth();

    let menuItem = null;

    if (
        isLoggedIn
        // &&
        // isUserAdmin
        // && user.role === 'admin'
    ) {
        menuItem = authMenuItems;
    } else {
        menuItem = noAuthMenuItems;
    }

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
