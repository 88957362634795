// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

const buildRoleCountObject = (townsfolk, outsider, minion, demon) => {
    if (townsfolk === null) return null;
    return {
        townsfolk,
        outsider,
        minion,
        demon
    };
};

export const roleCountByScriptType = (type) => {
    switch (type) {
        case 'RAVENSWOOD':
            return buildRoleCountObject(13, 4, 4, 4);
        case 'TEENSYVILLE':
            return buildRoleCountObject(6, 2, 2, 1);
        case 'PHOBOS':
            return buildRoleCountObject(null);
        default:
            return buildRoleCountObject(null);
    }
};

export const CustomRolesFilter = {
    NONE: 'none',
    OWN: 'own',
    ALL: 'all'
};

export const getRolesOfType = (roleData, type) => {
    const returnRoles = [];

    roleData?.forEach((role) => {
        if (role.type === type) {
            returnRoles.push(role);
        }
    });

    returnRoles.sort((a, b) => a.name.localeCompare(b.name));

    return returnRoles;
};
//
// export enum ScriptFilterTypes{
//     SCRIPT_TYPE
// }

// export const ScriptTypes = {
//     RAVENSWOOD: 'RAVENSWOOD',
//     OWN: 'own',
//     ALL: 'all'
// };

// export const enum CustomRolesFilter {
//     NONE,
//     OWN,
//     ALL
// };
